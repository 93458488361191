import { createAction } from "@reduxjs/toolkit"
import { NormalizedSchema, normalize, schema } from "normalizr"

import { Environment } from "~/shared/api/environment"
import { List } from "~/shared/api/lists"
import {
  DenormalizedTargetAttribute,
  DenormalizedTemplate,
  TargetAttribute,
  Template,
} from "~/shared/api/templates"
import { DenormalizedWorkspace, Workspace } from "~/shared/api/workspaces"

const TargetAttributeSchema = new schema.Entity("targetAttributes", {})

const TemplateSchema = new schema.Entity(
  "templates",
  { targetAttributeIds: [TargetAttributeSchema] },
  {
    processStrategy: (template) => {
      if (template.targetAttributes) {
        template.targetAttributeIds = template.targetAttributes
        delete template.targetAttributes
      }
      return template
    },
  },
)

const ListSchema = new schema.Entity("lists", {})

const WorkspaceSchema = new schema.Entity(
  "workspaces",
  { listIds: [ListSchema] },
  {
    processStrategy: (workspace) => {
      if (workspace.lists) {
        workspace.listIds = workspace.lists
        delete workspace.lists
      }
      return workspace
    },
  },
)

const EnvironmentSchema = new schema.Entity("environments", {})

export interface NormalizedEntities {
  templates: { [id: number]: Template }
  targetAttributes: { [id: number]: TargetAttribute }
  workspaces: { [id: number]: Workspace }
  lists: { [id: number]: List }
  environments: { [id: number]: Environment }
}

// This action is used to initialize multiple slices of the global store at once
// given the result of calling normalize on a central data structure.
export const partialInitSchema =
  createAction<Partial<NormalizedEntities>>("schema/partial-init")

// The entities field in the NormalizedSchema returned by normalize only has
// keys for the types of objects that it saw. We set it to empty object so
// that we can freely access entities without worrying about them being null.
function setAllEntities(entities: Partial<NormalizedEntities>) {
  if (!entities.templates) {
    entities.templates = {}
  }
  if (!entities.targetAttributes) {
    entities.targetAttributes = {}
  }
  if (!entities.workspaces) {
    entities.workspaces = {}
  }
  if (!entities.lists) {
    entities.lists = {}
  }
  if (!entities.environments) {
    entities.environments = {}
  }
}

// These are thin, typed wrappers around normalize that handle passing
// in the second argument (the schema format of the input), based on the
// different shapes of data we get from the backend.

// Bizzarely, TypeScript doesn't complain about the types if we just do:
//
// return normalize(input, schema)
//
// but when we do:
//
// const normalized = normalize(input, schema)
// setAllEntities(normalized)
// return normalized
//
// it doesn't like it anymore.

export function normalizeTemplate(
  template: DenormalizedTemplate,
): NormalizedSchema<NormalizedEntities, number> {
  const normalized = normalize(template, TemplateSchema) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeTemplates(
  templates: DenormalizedTemplate[],
): NormalizedSchema<NormalizedEntities, number[]> {
  const normalized = normalize(templates, [TemplateSchema]) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeTargetAttribute(
  targetAttribute: DenormalizedTargetAttribute,
): NormalizedSchema<NormalizedEntities, number> {
  const normalized = normalize(targetAttribute, TargetAttributeSchema) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeWorkspace(
  workspace: DenormalizedWorkspace,
): NormalizedSchema<NormalizedEntities, number> {
  const normalized = normalize(workspace, WorkspaceSchema) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeWorkspaces(
  workspaces: DenormalizedWorkspace[],
): NormalizedSchema<NormalizedEntities, number[]> {
  const normalized = normalize(workspaces, [WorkspaceSchema]) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeEnvironment(
  environment: Environment,
): NormalizedSchema<NormalizedEntities, number> {
  const normalized = normalize(environment, EnvironmentSchema) as any
  setAllEntities(normalized.entities)
  return normalized
}

export function normalizeEnvironments(
  environments: Environment[],
): NormalizedSchema<NormalizedEntities, number[]> {
  const normalized = normalize(environments, [EnvironmentSchema]) as any
  setAllEntities(normalized.entities)
  return normalized
}

export {
  TargetAttributeSchema,
  TemplateSchema,
  ListSchema,
  WorkspaceSchema,
  EnvironmentSchema,
}
