/*
 * WARNING: THIS IS AN AUTOGENERATED-FILE. DO NOT MANUALLY EDIT THIS FILE.
 *
 * MODIFY FLAGS DATA IN lib/gating.rb AND RUN `bundle exec bin/generate-ts-constants`
 * TO REGENERATE THIS FILE.
 */

export enum Feature {
  ProdEnvironmentIndicator = "ProdEnvironmentIndicator",
  ManageOrganizations = "ManageOrganizations",
  DeveloperMode = "DeveloperMode",
  ColumnHooks = "ColumnHooks",
  EventHooks = "EventHooks",
  AllowRemoveLogo = "AllowRemoveLogo",
  AdvancedBranding = "AdvancedBranding",
  I18N = "I18N",
  HideImportActivityPreview = "HideImportActivityPreview",
  CustomColumns = "CustomColumns",
  ValidationWebhooks = "ValidationWebhooks",
  Headless = "Headless",
  HeadlessValidation = "HeadlessValidation",
  DynamicTemplates = "DynamicTemplates",
  AuditLogs = "AuditLogs",
  GptExperimental = "GptExperimental",
  AiMapping = "AiMapping",
  ExtendedMetadataWebhookTimeout = "ExtendedMetadataWebhookTimeout",
  AdvancedMappings = "AdvancedMappings",
  CustomEnvironments = "CustomEnvironments",
  CodeHooksV1 = "CodeHooksV1",
  Customizations = "Customizations",
  Integrations = "Integrations",
  OneClickMode = "OneClickMode",
  FilterViewdataExternalApiTemplates = "FilterViewdataExternalApiTemplates",
  TestMaskDataHighlighting = "TestMaskDataHighlighting",
  InAppBilling = "InAppBilling",
  LegacyEventWebhooks = "LegacyEventWebhooks",
  ValidationWebhooksUnmappedCols = "ValidationWebhooksUnmappedCols",
  TemplateColumnsOrder = "TemplateColumnsOrder",
  LockColumns = "LockColumns",
  SheetOperationEventWebhook = "SheetOperationEventWebhook",
  CsAnalystImport = "CsAnalystImport",
  UserRoleManagement = "UserRoleManagement",
  FileFeeds = "FileFeeds",
  SelfServeTrial = "SelfServeTrial",
  FileFeedPartialImport = "FileFeedPartialImport",
  FileFeedImportsDemoMode = "FileFeedImportsDemoMode",
  ServerReachabilityPing = "ServerReachabilityPing",
  DebugTemplatesEndpointLag = "DebugTemplatesEndpointLag",
  EmbeddedPipelineBuilder = "EmbeddedPipelineBuilder",
  FileFeedCustomMetadata = "FileFeedCustomMetadata",
  MultiFileFeedsPerPipeline = "MultiFileFeedsPerPipeline",
  FileFeedInternalS3 = "FileFeedInternalS3",
  FileFeedEventWebhooks = "FileFeedEventWebhooks",
  DisableDateAutoFormatting = "DisableDateAutoFormatting",
  FileFeedCodeSnippetPage = "FileFeedCodeSnippetPage",
  SuggestedTransforms = "SuggestedTransforms",
  FileFeedPushedTemplates = "FileFeedPushedTemplates",
}

export enum PermanentFeature {
  WorkspaceCustomerIdentifier = "WorkspaceCustomerIdentifier",
  Importer = "Importer",
  CodeHooksApi = "CodeHooksApi",
  IncreasedCodeHookTimeout = "IncreasedCodeHookTimeout",
}
