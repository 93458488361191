import { caseConverterClient } from "~/shared/api/client"
import { FileFeedJwtPayload } from "~/shared/api/fileFeedsEmbedded"

import { ImporterJwtParams } from "./orgs"

export const ENVIRONMENT_NAME_PRODUCTION = "Production"
export const ENVIRONMENT_NAME_DEVELOPMENT = "Development"
export const ENVIRONMENT_NAME_SANDBOX = "Sandbox"

export interface Environment {
  id: number
  name: string
  allowedDomains: string[]
  clientId: string
  secretKey?: string
}
export interface EnvironmentParams {
  name: string
  allowedDomains: string[]
}

export async function createEnvironment(params: EnvironmentParams) {
  return caseConverterClient.post<Environment>("/api/environments", params)
}

export async function regenerateSecretKey(environmentId: number) {
  return caseConverterClient.patch<Environment>(
    `/api/environments/${environmentId}/regenerate-secret-key`,
  )
}
export async function updateEnvironment(
  environmentId: number,
  params: EnvironmentParams,
) {
  return caseConverterClient.put<Environment>(
    `/api/environments/${environmentId}`,
    params,
  )
}

export async function deleteEnvironment(environmentId: number) {
  return caseConverterClient.delete(`/api/environments/${environmentId}`)
}

export async function generateJWTForEnvironment(
  environmentId: number,
  jwtPayload: ImporterJwtParams,
) {
  return caseConverterClient.post<{ jwt: string }>(
    `/api/environments/${environmentId}/generate-jwt`,
    {
      jwtPayload: JSON.stringify(jwtPayload),
    },
  )
}

export async function generateFileFeedJwtForEnvironment(
  environmentId: number,
  jwtPayload: FileFeedJwtPayload,
) {
  return caseConverterClient.post<{ jwt: string }>(
    `/api/environments/${environmentId}/file-feed-jwt`,
    {
      jwtPayload: JSON.stringify(jwtPayload),
    },
  )
}

export interface PushedTemplateIdsPerEnvironment {
  [environmentId: string]: number[]
}
export async function getPushedTemplateIdsPerEnvironment() {
  return caseConverterClient.get<PushedTemplateIdsPerEnvironment>(
    "/api/environments/pushed-templates",
  )
}
