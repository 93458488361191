import React, { ReactElement } from "react"
import { Navigate, Outlet } from "react-router-dom"

import AccessDeniedPage from "~/shared/components/global/AccessDeniedPage"
import NotFound404Page from "~/shared/components/NotFound404Page"
import viewManager, { showInternalTools } from "~/shared/util/viewManager"

const isUserAuthenticated = () => {
  return Boolean(viewManager.get("user"))
}

export default function ProtectedRoute(): ReactElement | null {
  if (isUserAuthenticated()) {
    return <Outlet />
  }
  return <Navigate to="/login" replace />
}

export function InternalAdminOrgOnlyRoute(): ReactElement | null {
  if (showInternalTools()) {
    return <Outlet />
  }
  return <NotFound404Page />
}

interface ProtectedAccessRouteProps {
  canAccess: boolean
}

export function ProtectedAccessRoute({
  canAccess,
}: ProtectedAccessRouteProps): ReactElement | null {
  if (canAccess) {
    return <Outlet />
  }
  return <AccessDeniedPage />
}
