interface WindowWithParentOrigin extends Window {
  parentOrigin?: string
}

export function postMessageToParentWindow(msg: Record<string, any>) {
  const { parentOrigin } = window as WindowWithParentOrigin

  if (parentOrigin) {
    window.parent.postMessage(msg, parentOrigin)
  }
}

export function initiateDownload(url: string): void {
  // TODO: Use a safer way to download files.
  window.location.href = url
}
