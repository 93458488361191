import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-alpine.css"
import { loader as monacoloader } from "@monaco-editor/react"
import * as Sentry from "@sentry/react"
import "antd/dist/antd.variable.min.css"
import I18N from "i18next"
import LngDetector from "i18next-browser-languagedetector"
import backend from "i18next-http-backend"
import ICU from "i18next-icu"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { Provider } from "react-redux"

import BaseRouter from "~/shared/components/BaseRouter"
import ThemeProvider from "~/shared/containers/ThemeProvider"
import store from "~/shared/redux/store"
import "~/shared/styles/global.css"
import "~/shared/styles/root.less"
import { isEmbed } from "~/shared/util/embed"
import { ROOT_HOST } from "~/shared/util/viewManager"

import { registerAgGrid } from "./util/agGrid"

void registerAgGrid()

// Set monaco's editor version to latest
monacoloader.config({
  paths: {
    vs: "https://fastly.jsdelivr.net/npm/monaco-editor@0.39.0/min/vs",
  },
})

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://eca3dea8ec6f4320a3b48648c9aabdf8@o641017.ingest.sentry.io/5757481",
    environment: ROOT_HOST,
    release: process.env.GIT_SHA,

    // We don't use Sentry's performance monitoring right now,
    // so we also don't have to sample any data.
    // Tracing and error reporting is _not_ the same.
    tracesSampleRate: 0.0,

    // Enable offline storage.
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
      dbName: "oneschema-sentry-offline",
      maxQueueSize: 100,
    },

    ignoreErrors: [
      "Cannot redefine property: googletag",
      "grecaptcha is not defined",
      "ResizeObserver loop completed with undelivered notifications.",
      "ethereum",
      "Not implemented on this platform", // from LastPass extension
    ],

    // Attach stack traces to all captures, not just exceptions.
    attachStacktrace: true,

    // Ignore events originated from FullStory or Chrome extensions.
    beforeSend(event: Sentry.Event): Sentry.Event | null {
      try {
        const ex: Sentry.Exception | undefined = event.exception?.values?.[0]
        const frame: Sentry.StackFrame | undefined = ex?.stacktrace?.frames?.[0]
        const filename: string | undefined = frame?.filename
        if (
          filename &&
          ([
            // FullStory
            "s/fs.js",
            "/s/fs.js",
            // Browser extensions?
            "<anonymous>",
          ].includes(filename) ||
            filename.startsWith("chrome-extension://"))
        ) {
          return null
        }
      } catch (err) {
        // Ignore any errors, let the event be logged.
      }
      return event
    },
  })
}

const detector = new LngDetector(null, { caches: [] })

void I18N.use(detector)
  .use(ICU)
  .use(backend)
  .use(initReactI18next)
  .init({
    // only localize embed (setting undefined will use auto-detector)
    lng: isEmbed() && window.viewData?.features?.I18N ? undefined : "en",
    returnNull: false,
    fallbackLng: "en",
    load: "languageOnly",
    parseMissingKeyHandler: (key) => {
      // These strings are from converted enum from Ruby land that
      // we've decided not to translate
      const expectedErrors = [
        "Country",
        "Countries, dependent territories, and other areas",
        "US States or Territories",
        "US State or Territory",
      ]
      if (!expectedErrors.includes(key)) {
        Sentry.captureMessage(
          `Missing translation key: "${key}" for language ${I18N.language}`,
        )
      }
      return key
    },
    debug: process.env.NODE_ENV !== "production",
    saveMissing: false,
    backend: {
      loadPath: `${process.env.ASSET_PATH}locales/{{lng}}/{{ns}}.${process.env.I18N_HASH}.json`,
      addPath: undefined,
    },
    keySeparator: ".",
    interpolation: {
      // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
    i18nFormat: {
      bindI18nStore: "added",
    },
    react: {
      bindI18nStore: "added",
    },
  })

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.Suspense fallback={null}>
    <ThemeProvider parent={document.body}>
      <Provider store={store}>
        <BaseRouter />
      </Provider>
    </ThemeProvider>
  </React.Suspense>,
)
