import { QueryClient } from "@tanstack/react-query"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import axiosRetry, { isNetworkError } from "axios-retry"

import { postErrorV2ToParentWindow } from "~/shared/api/importerEvents"
import { SECOND } from "~/shared/constants"
import { fastJsonStringify } from "~/shared/util/json"
import { logEvent } from "~/shared/util/logging"
import { monitorServerReachability } from "~/shared/util/networkMonitor"
import { JsonValue, isDate } from "~/shared/util/types"

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { "Content-Type": "application/json" },
  timeout: 45 * SECOND,
}

const AXIOS_RETRY_CONFIG = {
  retries: 5,
  retryCondition: isNetworkError,
  retryDelay: (retryNumber: number) => axiosRetry.exponentialDelay(retryNumber),
  onRetry: function onRetry(
    retryCount: number,
    error: AxiosError,
    requestConfig: AxiosRequestConfig,
  ) {
    logEvent("info", "Axios retry attempt", {
      extra: {
        error,
        retryCount,
        "requestConfig.url": requestConfig.url,
        "requestConfig.method": requestConfig.method,
        "requestConfig.axiosRetry": requestConfig["axios-retry"],
      },
    })
  },
}

// == Case-Converting Client ==

/**
 * A client that applies snake_case -> camelCase conversions automatically.
 */
export const caseConverterClient = axios.create(DEFAULT_CONFIG)
applyCaseMiddleware(caseConverterClient, {
  ignoreHeaders: true,
})

// == Case-Preserving Client ==

/**
 * A client that doesn't apply automatic snake_case -> camelCase conversions.
 * Use this if you need the original casing for whatever reason. When using
 * this, the request params must be snake_case AND the returned data will be in
 * snake_case.
 */
export const originalCaseClient = axios.create(DEFAULT_CONFIG)

// == Event loggers ==

for (const client of [caseConverterClient, originalCaseClient]) {
  client.interceptors.request.use(null, onRequestError)
  client.interceptors.response.use(on2xxResponse, onOtherResponse)
  axiosRetry(client, AXIOS_RETRY_CONFIG)
}

function onRequestError(error: any) {
  logEvent("error", `http-req-error: ${error}`, {
    exception: error as Error,
  })

  return Promise.reject(error)
}

const EXPECTED_2XX_STATUS_CODES = new Set([200])

function on2xxResponse(response: AxiosResponse) {
  if (!EXPECTED_2XX_STATUS_CODES.has(response.status)) {
    const {
      request,
      status,
      statusText,
      config: { method, url },
    } = response
    logEvent("warn", `http-unexp-2xx: ${status} ${statusText} for ${method} ${url}`, {
      extra: {
        "request.readystate": request?.readyState,
        "request.responseType": request?.responseType,
        "request.responseURL": request?.responseURL,
      },
    })
  }

  return response
}

const KNOWN_SERVER_RESPONSE_STATUS_CODES: Set<number | "UNK"> = new Set([
  403, // Forbidden
  422, // Unprocessable Content
])

function onOtherResponse(error: any) {
  postErrorV2ToParentWindow("HTTP Error")

  const { response, config: { method = undefined, url = undefined } = {} } = error || {}
  const responseStatus = (response as AxiosResponse | undefined)?.status ?? "UNK"

  if (!KNOWN_SERVER_RESPONSE_STATUS_CODES.has(responseStatus)) {
    void monitorServerReachability(`http-${responseStatus}`)
  }

  logEvent("error", `http-error: ${method} ${url}: ${error}`, {
    exception: error as Error,
  })

  return Promise.reject(error)
}

// == Other helpers ==

// Used when embedding to configure the auth token header
// to be sent with every request.
export function setEmbedHeaderAuthToken(authToken: string) {
  caseConverterClient.defaults.headers.common["X-One-Schema-Embed-Auth-Token"] = authToken
  originalCaseClient.defaults.headers.common["X-One-Schema-Embed-Auth-Token"] = authToken
}

export type Response<T> = Promise<AxiosResponse<T>>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
})

type ParamValue = JsonValue | Date

/**
 * Encodes a single query param
 */
const encodeAxiosParam = (value: ParamValue) => {
  let encValue: string | number | boolean
  if (isDate(value)) {
    // Date
    encValue = value.toISOString()
  } else if (typeof value === "object") {
    // Object, Array, null
    encValue = fastJsonStringify(value)
  } else {
    // string, number, boolean
    encValue = value
  }
  return encodeURIComponent(encValue)
}

/**
 * Serializes params to a query string like axios pre-v1
 * This is a known issue with axios v1.
 * This code is borrowed from https://github.com/axios/axios/issues/5630#issuecomment-1859776798
 *
 * {
 *   object: { a: 1 },                        // object=%7B%22a%22:1%7D
 *   arrayOfArray: [[1, 2, ","], [2]],        // arrayOfArray[]=[1,2,%22,%22]&arrayOfArray[]=[2]
 *   arrayOfNullUndefined: [null, undefined], // arrayOfNullUndefined[]=null&arrayOfNullUndefined[]=undefined
 *   arrayOfObject: [{ id: 1 }, { id: 12 }],  // arrayOfObject[]=%7B%22id%22:1%7D&arrayOfObject[]=%7B%22id%22:12%7D
 *   arrayOfString: ["a", "b", "c"],          // arrayOfString[]=a&arrayOfString[]=b&arrayOfString[]=c
 *   undefinable: undefined,                  // X
 *   nullable: null,                          // X
 *   emptyArray: [],                          // X
 *   emptyString: "",                         // emptyString=
 *   emptyObject: {},                         // emptyObject=%7B%7D
 *   date: new Date("1970-01-01"),            // date=1970-01-01T00:00:00.000Z
 * }
 *
 * FIXME: Remove this in favor of updating the API endpoints
 */
export const paramsSerializerV0 = (params: { [key: string]: ParamValue }) =>
  Object.entries(params)
    .filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : true),
    )
    .map(([key, value]: [string, ParamValue]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join("&")
      }
      return `${key}=${encodeAxiosParam(value)}`
    })
    .join("&")
